<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-file-upload-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Cargas de CSV</div>
          </template>
          <v-btn
            v-if="accessReleased('CARGAS_CSV_ADICIONAR')"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('add')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Nova carga
          </v-btn>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-cargas-csv"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
          <general-progress-bars
            class="mt-11"
            :items="downloadProgressData"
            prefixText="Baixando logs ..."
          />
          <v-divider class="mt-10" />
          <v-data-table
            class="cargas-csv-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Arquivo CSV',
                value: 'arquivo'
              },
              {
                text: 'Tabela Destino',
                value: 'nome_completo_tabela_destino'
              },
              {
                text: 'Empresa',
                value: 'empresa'
              },
              {
                text: 'Nº de linhas',
                value: 'numero_linhas_carregadas'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="cargas"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhuma carga de CSV encontrada"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.numero_linhas_carregadas`]="{ item }">
              {{ item.numero_linhas_carregadas | parseNumberToIntegerBR }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :class="!podeBaixarZip(item.status) ? 'cursor-block' : ''"
                class="px-1"
                min-width="0"
                fab
                icon
                x-small
                @click="baixarZip(item)"
                :loading="
                  downloadProgressData[item.id] !== undefined ? true : false
                "
                :disabled="!podeBaixarZip(item.status)"
              >
                <v-icon small> mdi-download </v-icon>
              </v-btn>
              <v-btn
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.created
                    })
                "
              >
                <v-icon medium> mdi-table-search </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getCargas"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Clique aqui para recarregar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
        <dialog-creation-log
          :dialog.sync="dialogCreationLog"
          :item="dialogCreationLogData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import CargasCsvService from '@/services/CargasCsvService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';
import DialogCreationLog from '@/components/general/DialogCreationLog';
import GeneralStatus from '@/components/general/GeneralStatus';
import GeneralProgressBars from '@/components/general/GeneralProgressBars';

export default {
  name: 'CargasCsvHistorico',
  mixins: [refreshDataMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog,
    GeneralStatus,
    GeneralProgressBars
  },
  data: () => ({
    cargas: [],
    search: '',
    loading: false,
    dialogCreationLog: false,
    dialogCreationLogData: {},
    downloadProgressData: {}
  }),
  mounted() {
    this.refreshData(this.getCargas);
  },
  methods: {
    getCargas() {
      this.loading = true;
      CargasCsvService.getCargas()
        .then((cargas) => (this.cargas = cargas))
        .catch((err) => {
          console.error('Erro: ', err);
          this.$toast.error('Erro ao buscar as cargas de CSV.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },
    podeBaixarZip(status) {
      return status === 'SUCESSO' || status === 'FALHA';
    },
    baixarZip(carga) {
      const { id } = carga;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `carga-arquivo-csv-${id}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      CargasCsvService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            'Erro ao baixar o arquivo zip com os logs da carga do arquivo CSV.',
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  }
};
</script>
