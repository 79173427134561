var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-file-upload-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Cargas de CSV")])]},proxy:true}])},[(_vm.accessReleased('CARGAS_CSV_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova carga ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-cargas-csv",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando logs ..."}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"cargas-csv-table",attrs:{"headers":[
            {
              text: '#',
              value: 'id'
            },
            {
              text: 'Arquivo CSV',
              value: 'arquivo'
            },
            {
              text: 'Tabela Destino',
              value: 'nome_completo_tabela_destino'
            },
            {
              text: 'Empresa',
              value: 'empresa'
            },
            {
              text: 'Nº de linhas',
              value: 'numero_linhas_carregadas'
            },
            {
              text: 'Status',
              value: 'status'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.cargas,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhuma carga de CSV encontrada","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.numero_linhas_carregadas",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas_carregadas))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!_vm.podeBaixarZip(item.status) ? 'cursor-block' : '',attrs:{"min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":!_vm.podeBaixarZip(item.status)},on:{"click":function($event){return _vm.baixarZip(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-btn',{staticClass:"px-1",attrs:{"min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){(_vm.dialogCreationLog = true),
                  (_vm.dialogCreationLogData = {
                    username: item.usuario,
                    created: item.created
                  })}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-table-search ")])],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getCargas}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }